.list{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0;
    /* padding-top: 30px; */
    margin: 0 auto;
    /* border: 1px dotted white; */
    width: 80%;
   }


  /* .list__button{
    /*подумать над ::before и ::hover*/
     /* width:100px;
     height: 15px;
     cursor: pointer;
     position: relative;
     overflow: hidden;
     margin: 0;
     margin-right: 10px;
     padding: 0; */
     /* background-color: white; */
     /* border: none;
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 700;
     font-size: 12px;
     line-height: 12px;
     color: black;
    }  */

    .list__input{
        position: relative;
        border: none;
        width: 100%;
        /* padding: 19px 4px 9px 4px; */
        /* You can use your image but having cleaner code is better, so I suggest saving the file and just linking it*/
        /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z'/%3E%3C/svg%3E") ;*/
        background-image: url("../../images/magnifiyng_glass.svg");
        background-size: 13px;
        background-repeat: no-repeat;
        background-position: 10px center;
        padding-left: 25px;
        background-color: #191919;
        color: white;
        font-size: 14px;
        border: 2px solid white;
        border-radius: 12px;
    }

    .list__input::placeholder{
        color: #E5E4E2;
        font-size: 12px;
        opacity: 0,8;
    }

    .list__dropdown{
        position: absolute;
        /* margin-top: 100px; */
        top: 50%;
        /* padding-left: 25px; */
        /* margin-left: 40px; */
        color: black;
        background-color: white;
        list-style-type: none;
        margin-top:  0;
        padding: 0;
        border: 1px solid white;
        overflow:auto;
        width: 100%;
        z-index: 2;
        font-family: inherit;
    }
    
    .list__dropdown-year{
        position: relative;
        z-index: 3;
        margin:0;
    }
    
    .list__dropdown-article-button{
        cursor: pointer;
        background-color: inherit;
        width: 100%;
        height: 25px;
        color: white;
        border: none;
        margin-bottom: 10px;
        border: 1px white solid;
        border-radius: 12px;
        min-width: 70px;
        font-family: inherit;
    }
    
    .list__dropdown-article-button:before {
      position: absolute;
      content: " ";
      height: 7px;
      width: 7px;
      border: 2px solid white;
      border-left-width: 0px;
      border-top-width: 0px;
      top: 5px;
      right: 10px;
      transform: rotate(45deg);
      z-index: 2;
    }
    
    
    .list__dropdown-article-button:hover{
      opacity: 0.7;
    }
    
    .list__dropdown-list{
        position: absolute;
        list-style-type: none;
        margin-top:  0;
        padding: 0;
        opacity: 0;
        pointer-events: none;
        border: 1px solid grey;
        overflow:auto;
        transform: translateY(-10px);
        transition: max-height 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
      }
    
    .list__dropdown-list_active{
        opacity: 1;
        pointer-events: auto;
        transform: translateY(-2px);
    }
    
    .list__dropdown-list_items{
        width: 140px;
        max-height:220px;
    }
    
    .list__dropdown-list_metrics{
        width: 220px;
        max-height:220px;
    }
    
    .list__dropdown-list_years{
      width: 100%;
      max-width: 150px;
      max-height:220px; 
    }
    
    .list__dropdown-text{
        font-family: inherit;
        font-size: 12px;
    }
    
    .list__dropdown-button {
        width: 100%;
        height: 60%;
        min-height: 30px;
        text-align: left;
        background: none;
        color: inherit;
        border: none;
        /* padding: 5px; */
        margin: 0;
        padding: 0;
        font: inherit;
        cursor: pointer;
        background-color: white;
    }
    
      .list__dropdown-button:hover {
        background-color: lightgray;
      }
    
      .list__dropdown-button_main{
        font-weight: bold;
      }
    /* .list__dropdown:hover {
        background-color: lightgray;
    } */

    .list__search-form{
        position: relative;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .list__search-container{
        display: flex;
        flex-direction: row;
        justify-content:flex-end;
        align-items: center;
        color: white;
        /* border: 1px solid currentColor; */
        border-radius: 5px;
        margin: 0;
        margin-bottom: 20px;
        /* margin-left: 40px; */
        max-width: 100%;
    }

    .list__dropdown:empty {
        border: none;
    }
    .list__dropdown:empty {
        cursor: pointer;
    }
    
    .list_dropdown-row{
        cursor: pointer;
        font-family: inherit;
        padding: 10px 0;
        font-size: 12px;
    }
    
    .list_dropdown-row:hover {
        background-color: lightgray;
    }


    .list__search-button{
        cursor: pointer;
        background-color: inherit;
        margin: 0;
        /* font-size: 10px;
        background-color: black;
        color: white;
        border: 1px dotted white; */
        border: none;
    }
    /* .list__search-button_triangle{
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 20px solid white;
    } */
    
    @media screen and (max-width: 768px) {
        .list{
            width: 100%;
            padding: 0;
            justify-content: space-evenly;
            align-items: center; 
        }
        .list__search-container{
            display: flex;
            flex-direction: row;
            justify-content:flex-end;
            align-items: flex-start;
            border-radius: 5px;
            margin-bottom: 0;
            margin-left: 0;
            max-width: 100%;
        }

        .list__search-form{
            margin: 20px 0;
        }
    
        .list__dropdown{
            top: 100%;
            left: 0%;
            z-index: 2;
        }
        .list__input{
            position: relative;
            /* border: 1зч ; */
            width: 40vw;
            height: 30px;
        }
        .list__dropdown-article-button{
            height: 36px;
            font-size: 14px;
            margin-bottom: 0px;
        }
        .list__dropdown-article-button:before {
              top: 10px;
          }
    }