.line-graph {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    max-width: 100%;
    height: 100%;
    background-color: inherit;
    border-radius: 10px;
    /* padding-top: 50px; */
    padding-bottom: 100px;
  }
  
  .line-graph__title {
    text-align: center;
    color:white;
    margin: 15px auto 10px;
    font-weight: bold;
    font-size: 22px;
  }
  
  .line-graph__subtitle{
    text-align: center;
    color:white;
    font-size: 18px;
    margin: 0;
  }


  .line-graph__container {
    display: inline-block;
    position: relative;
    width: 1200px;
    height: 300px;
    resize: both;
    object-fit: cover;
    overflow: visible;
  }
  
  .line-graph__graphics {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid white;
    background-color:  #212020;
    border-radius: 10px;
  }
  
  /* Axis styles */
  .line-graph__axis {
    color: white;
    font-family: inherit;
    font-size: 12px;
  }
  
  .line-graph__axis .domain {
    stroke: white;
  }
  
  .line-graph__axis text {
    fill: white;
  }
  
  /* Grid lines */
  .grid line {
    stroke: white;
    stroke-dasharray: 2,2;
  }

  .line-graph__buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 300px;
    margin: 15px 0;
  }

  .line-graph__tooltip {
    /* display: none; */
    position: absolute;
    /* right: -100px; */
    text-align: center;
    /* min-width: 100px; */
    max-width: 200px;
    height: min-content;
    padding: 10px;
    font: 14px sans-serif;
    font-family: 'Gilroy';
    font-weight: bold;
    background: white;
    border: 2px solid white;
    border-radius: 8px;
    pointer-events: none;
    opacity: 1;
    white-space: pre-wrap;
    /* overflow: hidden; */
  }
  
  @media screen and (max-width: 1300px) {
    .line-graph__container {
      width: 500px;
    }
  }
  
  @media screen and (max-width: 1000px) {
    .line-graph__container {
      width: 500px;
      height: 300px;
    }
  }
  
  @media screen and (max-width: 750px) {
    .line-graph__container {
      width: 380px;
      height: 340px;
    }
    .line-graph__tooltip{
        width:180px;
      }
  }
  
  @media screen and (max-width: 450px) {
    .line-graph{
      padding-top: 50px; 
      margin: 0 auto;
    }

    .line-graph__container {
      width: 360px;
    }

    .line-graph__title{
      white-space: pre-wrap;
    }
    .line-graph__tooltip{
        width: 160px;
        padding: 5px 0;
    }
  }

  
  @media screen and (max-width: 370px) {
    .line-graph__container {
      width: 300px;
    }
  }
  