.center__page{
  display: flex;
  flex-direction: column;
  gap: 10px 2%;
  padding-bottom: 0px;
  background-color: #191919;
}

.center{
    display: flex;
    flex-direction: row;
    gap: 10px 2%;
    padding-bottom: 0px;
    background-color: #191919;
    padding-top: 100px;
    padding-bottom: 60px;
 }
  
  .center__title{
    grid-area: a;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: 1px dotted white;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: white;
  }


.center__dropdown{
  padding-left: 70px;
}

.center__dropdown-year{
    position: relative;
    z-index: 3;
    margin:0;
    padding-left: 70px;
}

.center__dropdown-article-button{
    position: relative;
    cursor: pointer;
    background-color: inherit;
    width: 70px;
    height: 25px;
    color: white;
    border: none;
    margin-bottom: 10px;
    border: 1px white solid;
    border-radius: 12px;
    min-width: 30px;
    font-family: inherit;
}

.center__dropdown-article-button:before {
  position: absolute;
  content: " ";
  height: 7px;
  width: 7px;
  border: 2px solid white;
  border-left-width: 0px;
  border-top-width: 0px;
  top: 5px;
  right: 10px;
  transform: rotate(45deg);
  z-index: 2;
}


.center__dropdown-article-button:hover{
  opacity: 0.7;
}

.center__dropdown-list{
    position: absolute;
    list-style-type: none;
    margin-top:  0;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    border: 1px solid grey;
    overflow:auto;
    transform: translateY(-10px);
    z-index: 2;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  }

.center__dropdown-list_active{
  opacity: 1;
  pointer-events: auto;
  transform: translateY(-2px);
  }

.center__dropdown-list_items{
    width: 70px;
    max-height:220px;
}

.center__dropdown-list_metrics{
    width: 220px;
    max-height:220px;
}

.center__dropdown-list_years{
  max-width: 150px;
  max-height:220px; 
}

.center__dropdown-item{
  width: 70px;
}

.center__dropdown-text{
    font-family: inherit;
    font-size: 12px;
}

.center__dropdown-button {
    width: 100%;
    min-height: 30px;
    text-align: left;
    background: none;
    color: inherit;
    border: none;
    margin: 0;
    padding: 0;
    font: inherit;
    cursor: pointer;
    background-color: white;
}

  .center__dropdown-button:hover {
    background-color: lightgray;
  }

  .center__dropdown-button_main{
    font-weight: bold;
  }

  .center__explanation{
    display:none;
  }

  .center__value{
    color: white;
    font-size: 16px;
    font-style: bold;
    margin: 0;
    margin-left: 53px;
    padding-bottom: 10px;
  }
  
  
  .center__treemap{
    grid-area: d;
    margin: 0 auto;
  }

  .center__list{
    grid-area: c;
  }

  .center__loading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: white;
    height: 550px;
    font-size: 32px;
  }
  
  .center__panel {
    grid-area: c;
    max-height: 530px;
    overflow-y: scroll;
    border-right: 2px solid white;
  }
  
  .center__map {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    border-right: solid 1px white;
    opacity: 0;
    transform: translateY(10px);
    /* Increase duration and use a cubic-bezier for smoother easing */
    transition: opacity 0.75s cubic-bezier(0.4, 0, 0.2, 1),
                transform 0.75s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: opacity, transform;
  }
  
  .center__map.active {
    opacity: 1;
    transform: translateY(0);
  }

  
  
  .center__test{
    color: white;
    text-align: center;
    margin-left: 0;
    padding-left: 0;
    width: 412px;
  }

  .center__value{
    position: relative;
    color: white;
    font-size: 16px;
    font-style: bold;
    margin: 0;
    padding-bottom: 10px;
    width: 100%;
    /* border-right:  solid 1px white; */
  }

  .center__highlight{
    color: #f6cf2d;
  }

  /* Define the fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation to elements with the fade-in class */
  .fade-in {
    animation: fadeIn 0.5s ease-out forwards;
  }

  .center__text{
    margin-bottom: 0;
  }

  .center__text-value{
    margin-top: 0;
  }


  @media screen and (max-width: 1150px) {
    .center__test{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 350px
    }
    
    .center__list{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 400px
    }
    
    .center__panel{
      max-height: 400px;
    }

    .center__value{
      font-size: 14px;
    }
  }


  

  @media screen and (max-width: 900px) {
    .center{
      display: flex;
      flex-direction: column;
    }

    .center__panel{
      max-height: none;
      overflow-y: none;
      border-right: none;
    }

    .center__list{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100vw;
  }
  
  .center__test { 
      display: flex; /* Switch to flexbox for proper horizontal layout */
      flex-direction: row;
      flex-wrap: nowrap; /* Ensure everything stays in a single row */
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
      gap: 10px; /* Space between items */
      scrollbar-width: none;
      /* scroll-padding: 0 10px; */
      padding: 5px 0;
      list-style: none; /* Remove bullet points */
      align-items: center;
}

  .center__map { /* Applies to all child elements inside .center__test */
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 0 33%; /* Forces each item to take exactly 33% of the container */
      min-width: 33%;
      max-width: 33%;
      text-align: center;
      padding: 10px;
      box-sizing: border-box;
      white-space: normal; /* Ensures text wraps properly */
      margin:0;
      padding: 0;
      border-right: none;
      border-bottom: 1px solid white;
      height: 80px; /* Fixed height */
  }




    .center__explanation{
      display:block;
      color: white;
      font-size: 12px;
      /* font-weight: bold; */
      white-space: pre-wrap;
      text-align: center;
      padding: 0;
      margin: 0;
    }
    .center__dropdown{
      padding-left: 10px;
    }

    .center__value{
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: none;
      border-bottom: 1px solid white;
      /* width: 16vw; */
      font-size: 12px;
      font-weight: bold;
      height: 80px;
      padding: 0;
      white-space: pre-wrap;
    }
  

    .center__loading{
      height: 500px;
    }
  
    .center__arrow {
      width: 0; 
      height: 0; 
      border-top: 5px solid transparent; 
      border-bottom: 5px solid transparent;
      border-left: 5px solid white;
      position:absolute;
      left:-5px;
      bottom:-5px;
    }
    
    .center__treemap{
      padding: 0 5vw;
      width: 90vw;
      margin-top: 20px;
    }
  
    .center__text {
      display: flex;
      flex-direction: column; /* Ensures text stacks properly */
      justify-content: flex-start; /* Ensures scrolling starts from the top */
      align-items: center; /* Keeps text horizontally centered */
      text-align: center;
      font-size: 12px;
      margin: 0;
      padding-top: 20px;
      height: 70px; /* Fixed height */
      overflow-y: auto; /* Enables scrolling */
      word-wrap: break-word; /* Ensures long words wrap properly */
      white-space: normal; /* Allows text to wrap */
  }

  .center__text-value{
    font-size: 12px;
    margin: 0;
  }
    
  .center__span{
      display: none;
    }
  } 

  @media screen and (max-width: 500px) {
    .center__loading{
      height: 400px;
    }
    .center__dropdown-article-button{
      height: 36px;
      font-size: 14px;
  }
    .center__dropdown-article-button:before {
        top: 10px;
    }
  
  }
  

  @media screen and (max-width: 345px) {
    .center__explanation{
    font-size: 10px;
    }
  }