 .treemap__container{
    display: inline-block;
    position: relative;
    width: 954px;
    height: 550px;
    resize: both;
    object-fit: cover;
    font-size: 50px;
    margin-bottom: 40px;
  }
  
  .treemap{
    display: flex;
    justify-content: center;
    position:absolute;
    /* width: 954px;
    height: 600px; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    object-fit: fill;
  }


.treemap__tooltip {
    /* display: none; */
    position: absolute;
    /* right: -100px; */
    text-align: center;
    /* min-width: 100px; */
    width: 200px;
    height: min-content;
    padding: 2px;
    font: 14px sans-serif;
    font-family: 'Gilroy';
    font-weight: bold;
    background: white;
    border: 2px solid white;
    border-radius: 8px;
    pointer-events: none;
    opacity: 1;
    white-space: pre-wrap;
    /* overflow: hidden; */
  }

  /* .treemap__tooltip_hidden{
    opacity: 0;
  } */

  .text{
    color:#d3d3d3;
  }

  .treemap_test{
    color:#d3d3d3;
  }

  .treemap__table-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: #191919;
    max-height: 550px;
    overflow-y: scroll;
  }
  
  .treemap__table-container_hidden{
    display: none;
  }

  .treemap__table{
    margin-top: 20px;
    color: white;
    border: 1px white solid;
    width: 100%;
    text-align: center;
  }

  .treemap__table-title{
    font-size: 18px;
    /* border: 1px white solid; */
  }

  .treemap__table-text{
    width: 80%;
    font-size: 16px;
    line-height: 1.5em;
    border-top: 1px white solid;
  }

  .treemap__view-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    color: white;
    /* margin-right: 10px; */
    cursor: pointer;
    padding: 0;
    margin-right: 10px;
  }

  .treemap__view-toggle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 42%;
  }

  .treemap__icon{
    width: 20px;
    height: 20px;
    border: none;
  }

  .treemap__view-button_active{
    border: 1px solid white;
    border-radius: 2px;
  }

  @media screen and (max-width: 1300px) {
  .treemap__container{
    width: 750px;
    height: 450px;
  }
}

/* @media screen and (max-width: 1200px) {
  .treemap__container{
    width: 720px;
    height: 450px;
    margin-bottom: 40px;
  }
} */

@media screen and (max-width: 1150px) {
  .treemap__container{
    width: 650px;
    height: 400px;
    /* margin: 0 auto; */
  }
  .treemap__table-container{
    max-height: 420px;
  }
}

@media screen and (max-width: 1000px) {
  .treemap__container{
    width: 550px;
    height: 380px;
  }
  .treemap__table-container{
    max-height: 400px;
  }
}

  @media screen and (max-width: 900px) {
    .treemap__container{
      width: 720px;
      height: 500px;
      margin: 0 auto;
    }

    .treemap__table-container{
      max-height: 540px;
      padding-bottom: 50px;
    }
    
    .treemap{
     margin: 0 auto;
    }
  }

  @media screen and (max-width: 700px) {
    .treemap__container{
      width: 550px;
      height: 350px;
    }
    .treemap__table-container{
      max-height: 370px;
    }
    .treemap__table-title{
      font-size: 14px;
    }
    .treemap__table-text{
      font-size: 14px;
    }
  }

  @media screen and (max-width: 580px) {
    .treemap__container{
      width: 400px;
      height: 450px;
    }
    .treemap__tooltip{
      width:180px;
    }
    .treemap__table-container{
      max-height: 400px;
    }
  }

  @media screen and (max-width: 420px) {
    .treemap__container{
      width: 350px;
    }
    .treemap__tooltip{
      width:160px;
    }
  }

  @media screen and (max-width: 360px) {
    .treemap__container{
      width: 300px;
    }
  }