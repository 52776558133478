.about{
    padding-top: 65px;
    min-height: 500px;
    background-color: #191919;
}

.about__container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    margin: 2% 5%;
}
.about__title{
    font-size: 18px;
    color: white;
}

.about__text{
    font-size: 16px;
    color: white;
    font-weight: normal;
}

.about__list{
    margin-top: 0;
    list-style-type: none;
}

.about__currency{
    color: white;
}

