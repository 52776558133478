.loading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 100%;
}

.loading__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* spacing between squares */
  }
  
  .loading__spinner {
    width: 40px;
    height: 40px;
    animation: spin 3s linear infinite;
  }
  
  .loading__spinner_red {
    background-color: #507B58;
    animation-delay: 0s;
  }
  
  .loading__spinner_green {
    background-color: #f6cf2d;
    animation-delay: 0.2s;
  }
  
  .loading__spinner_blue {
    background-color: #AB3131;
    animation-delay: 0.4s;
  }

  .loading__text{
    color: white; 
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
   @media screen and (max-width: 800px) {
    .loading_treemap{
      height: 50vh;
  }
  }