.regions{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #191919;
  padding-top: 100px;
  padding-bottom: 0px;
}

.regions__title{
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: 1px dotted white;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-align: regions;
  color: white;
}

.regions__explanation{
  display:none;
}

.regions__loading{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: white;
  height: 550px;
  margin-right: 50px;
  font-size: 32px;
}

 .regions__value{
  position: relative;
  color: white;
  font-size: 16px;
  font-style: bold;
  margin: 0;
  padding-bottom: 10px;
  width: 100%;
  /* border-right:  solid 1px white; */
}

.regions__treemap{
  margin: 0;
  margin-right: 40px;
  width: 70vw;
}

.regions__list{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 500px
}

.regions__list_year{
 margin-top: 20px;
}

.regions__navigation{
  color: white;
}

.regions__test{
  color: white;
  text-align: center;
  margin-left: 0;
  padding-left: 0;
  width: 412px;
}

.regions__panel {
  grid-area: c;
  max-height: 530px;
  overflow-y: scroll;
  border-right: 2px solid white;
}

.regions__map {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  opacity: 0;
  transform: translateY(10px);
    /* Increase duration and use a cubic-bezier for smoother easing */
  transition: opacity 0.75s cubic-bezier(0.4, 0, 0.2, 1),
              transform 0.75s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: opacity, transform;
  }
  
.regions__map.active {
  opacity: 1;
  transform: translateY(0);
}

.regions__linegraph{
  background-color: #191919;
}

.regions__text{
  margin-bottom: 0;
}

.regions__text-value{
  margin-top: 0;
}

.regions__loading{
  margin: 0;
  padding: 0;
}

.regions__highlight{
  color: #f6cf2d;
}

@media screen and (max-width: 1150px) {
  .regions__test{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 350px
  }
  
  .regions__list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 400px
  }

  .regions__value{
    font-size: 14px;
  }
  
  .regions__panel{
    max-height: 400px;
  }
}

@media screen and (max-width: 900px) {
  .regions{
    flex-direction: column;
    padding-top: 60px;
  }
  .regions__treemap{
    margin: 0 auto;
    width:fit-content;
    margin-top: 20px;
  }

  .regions__list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
}

  .regions__panel{
    max-height: none;
    overflow-y: none;
    border-right: none;
  }

  .regions__test{
    display: flex; /* Switch to flexbox for proper horizontal layout */
    flex-direction: row;
    flex-wrap: nowrap; /* Ensure everything stays in a single row */
    overflow-x: auto; /* Enable horizontal scrolling */
    width: 100%;
    gap: 10px; /* Space between items */
    scrollbar-width: none;
    /* scroll-padding: 0 10px; */
    padding: 5px 0;
    list-style: none; /* Remove bullet points */
    align-items: center;
  }
  
  .regions__explanation{
    display:block;
    color: white;
    font-size: 12px;
    /* font-weight: bold; */
    white-space: pre-wrap;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .regions__loading{
    height: 500px;
  }

  
  .regions__value{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: none;
    border-bottom: 1px solid white;
    font-size: 12px;
    font-weight: bold;
    height: 80px;
    padding: 0;
  }

  .regions__map{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 33%; /* Forces each item to take exactly 33% of the container */
    min-width: 33%;
    max-width: 33%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    white-space: normal; /* Ensures text wraps properly */
    margin:0;
    padding: 0;
    border-right: none;
    border-bottom: 1px solid white;
    height: 80px; /* Fixed height */
  }

  .regions__arrow {
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent; 
    border-bottom: 5px solid transparent;
    border-left: 5px solid white;
    position:absolute;
    left:-5px;
    bottom:-5px;
  }
  

  .regions__text{
    display: flex;
    flex-direction: column; /* Ensures text stacks properly */
    justify-content: flex-start; /* Ensures scrolling starts from the top */
    align-items: center; /* Keeps text horizontally centered */
    text-align: center;
    font-size: 12px;
    margin: 0;
    padding-top: 20px;
    height: 70px; /* Fixed height */
    overflow-y: auto; /* Enables scrolling */
    word-wrap: break-word; /* Ensures long words wrap properly */
    white-space: normal; /* Allows text to wrap */
  }

  .regions__text-value{
    font-size: 12px;
    margin: 0;
  }
  
  .regions__span{
    display: none;
  }
} 


@media screen and (max-width: 500px) {
  .regions__loading{
    height: 400px;
  }
}


@media screen and (max-width: 345px) {
  .regions__explanation{
  font-size: 10px;
  }
}